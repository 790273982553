[data-v-6b8c1120] .el-button--mini {
  padding: 5px 3px;
  font-size: 18px !important;
  -webkit-transform: scale(0.8, 0.8);
          transform: scale(0.8, 0.8);
}
.fileName[data-v-6b8c1120] {
  cursor: pointer;
}
.shortName[data-v-6b8c1120] {
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.files-wrapper[data-v-6b8c1120] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.file-tag-list[data-v-6b8c1120] {
  border: 1px solid #d9ecff;
  background-color: #ecf5ff;
  line-height: 20px;
  color: #409eff;
  margin-right: 6px;
  margin-bottom: 4px;
}
.file-name[data-v-6b8c1120] {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.file-list-preview[data-v-6b8c1120] {
  cursor: pointer;
}
.file-list-replace[data-v-6b8c1120] {
  cursor: pointer;
  margin: 0 5px;
}
.file-list-delete[data-v-6b8c1120] {
  cursor: pointer;
  color: red;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  margin-left: 5px;
}
[data-v-6b8c1120] .pdf-viewer {
  height: 95% !important;
}
[data-v-6b8c1120] .pdf-viewer .el-dialog__body {
    height: calc(100% - 80px);
    overflow: hidden;
}
